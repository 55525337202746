import React from "react";
import {
  Avatar,
  Box,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import { Sync } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ReactComponent as FounderPass } from "assets/founder-pass.svg";

type Props = {
  player: any;
};

export const TopPlayer = (props: Props): JSX.Element => {
  const { player } = props;

  return (
    <ListItem alignItems="flex-start">
      <Avatar
        variant="rounded"
        sx={{ backgroundColor: "divider", color: "black" }}
      >
        {player.founderPassOwned}
      </Avatar>
      <Box sx={{ marginLeft: "1rem" }}>
        <Link to={`/addresses/${player.id}`}>
          <Typography variant="body1" sx={{ color: "blue" }}>
            {player.id}
          </Typography>
        </Link>
        <Typography variant="subtitle2">
          Minted {player.founderPassMinted}{" "}
        </Typography>
      </Box>
    </ListItem>
  );
};

export const TopPlayerSkeleton = (): JSX.Element => {
  return (
    <ListItem alignItems="flex-start">
      <Avatar
        variant="rounded"
        sx={{ backgroundColor: "divider", color: "black" }}
      >
        <Sync />
      </Avatar>
      <Box sx={{ marginLeft: "1rem" }}>
        <Skeleton height={28} width={400} variant="text" animation="wave" />
        <Skeleton height={15} width={300} variant="text" animation="wave" />
      </Box>
    </ListItem>
  );
};

export function tokenSort(a: any, b: any): number {
  return parseInt(a.tokenId, 10) - parseInt(b.tokenId, 10);
}

export function bigIntSort(prop: string) {
  return (a: any, b: any): number => {
    const x = parseInt(a[prop], 10);
    const y = parseInt(b[prop], 10);

    if (x > y) {
      return 1;
    } else if (y > x) {
      return -1;
    }
    return 0;
  };
}

export const formatAddress = (val: string): string => {
  return `${val.substring(0, 6)}...${val.substring(val.length - 5)}`;
};

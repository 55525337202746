import { Grid, Box, Typography } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import { isAddress } from "@ethersproject/address";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { Details, Overview } from "views/address";
import { TraitsProvider } from "hooks/useTraits";
import { mnaContract } from "utils/constants";

const GET_PLAYER = gql`
  query getPlayer($address: String!) {
    player(id: $address) {
      id
      founderPassOwned
      founderPassMinted
      numTokensMinted
      numTokensOwned
      aliensOwned
      marinesOwned
      aliensMinted
      marinesMinted
      aliensLost
      aliensStaked
      aliensStolen
      marinesLost
      marinesStaked
      marinesStolen
      spidoxOwned
      tokens(
        where: { contract: "0xdbe147fc80b49871e2a8d60cc89d51b11bc88b35" }
      ) {
        id
        tokenId
        typ
        isStaked
        stakedAt
        mintedAt
        mintTx
        victim
        thief
        image
        generation
        emblem
        rank
        eyes
        back
        body
        headgear
        weaponMouth
      }
    }
  }
`;

const contract = "0xdbe147fc80b49871e2a8d60cc89d51b11bc88b35";

const GET_TOKEN = gql`
  query getToken($id: String!) {
    token(id: $id) {
      id
      tokenId
      typ
      isStaked
      stakedAt
      mintBlock
      mintedAt
      mintTx
      victim
      thief
      metadata
    }
  }
`;
const GET_TOKENS = gql`
  query getTokens($tokenIds: [String]!) {
    tokens(where: { id_in: $tokenIds }) {
      id
      tokenId
      typ
      isStaked
      stakedAt
      mintBlock
      mintedAt
      mintTx
      victim
      thief
      metadata
    }
  }
`;

function attrsToProps(attrs: Array<{ trait_type: string; value: string }>): {
  [key: string]: string;
} {
  return attrs.reduce((memo: { [key: string]: string }, attribute) => {
    const { trait_type, value } = attribute;
    let trait = trait_type.split("_").pop();

    if (trait != null) {
      console.log("trait", trait);
      if (trait.includes("Eye")) {
        trait = "Eyes";
      }
      memo[trait.toLowerCase()] = value;
    }

    return memo;
  }, {});
}
export const ResultContainer = () => {
  const [tokenId, setTokenId] = useState<string>("");
  const { id } = useParams();
  const address = id ?? "";

  // TODO display error
  const validAddress = isAddress(address);
  const { loading, data } = useQuery(GET_PLAYER, {
    variables: { address: address?.toLowerCase() },
    skip: !validAddress,
  });
  const token = (data?.player?.tokens ?? []).find((t) => t.tokenId == tokenId);

  // TODO skeleton / spinner
  const tokens = data?.player?.tokens ?? [];
  const addressTitle = address === mnaContract ? 'Unclaimed Marines & Aliens' : address;

  return (
    <TraitsProvider>
      <Grid
        className="result-container"
        container
        direction="column"
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: "1400px",
          padding: "2vh 3vw 0 3vw",
        }}
      >
        <Grid className="overview" item xs={4}>
          <Overview player={data?.player} address={addressTitle} token={token} />
        </Grid>

        <Grid item xs={8} className="details">
          <Details tokens={tokens} setTokenId={setTokenId} loading={loading} />
        </Grid>
      </Grid>
    </TraitsProvider>
  );
};

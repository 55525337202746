import { Box } from "@mui/material";

import { PlayerDataGrid } from "views/game";
import { TraitsProvider } from "hooks/useTraits";
import { LandingSearchContainer } from "views/layout";

export const LandingContainer = () => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          backgroundImage: "url(/shapes.svg)",
          backgroundColor: "#081d30",
          height: "21vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LandingSearchContainer />
      </Box>
      <Box
        sx={{
          // marginTop: "-65px",
          marginTop: "2vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TraitsProvider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "1400px",
              width: "100%",
              padding: "0 3vw",
              height: "64vh",
            }}
          >
            <PlayerDataGrid />
          </Box>
        </TraitsProvider>
      </Box>
    </Box>
  );
};

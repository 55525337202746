import * as React from "react";
import { Box } from "@mui/material";
import { DataGrid, GridRowModel, GridCellParams } from "@mui/x-data-grid";
import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { client } from "clients/apolloClient";
import { mnaContract } from "utils/constants";
import { useTraits } from "hooks/useTraits";

const TOP_PLAYERS = gql`
  query getPlayers($count: Int!, $skip: Int!) {
    game(id: "SpaceGame") {
      numPlayers
    }
    players(
      first: $count
      orderBy: numTokensOwned
      orderDirection: desc
      skip: $skip
    ) {
      id
      aliensLost
      aliensMinted
      aliensOwned
      aliensStaked
      aliensStolen
      founderPassOwned
      founderPassMinted
      marinesLost
      marinesOwned
      marinesMinted
      marinesStaked
      marinesStolen
      numTokensOwned
      spidoxOwned
    }
  }
`;

const columns = [
  { width: 220, field: "displayName", headerName: "Address" },
  { width: 150, field: "numTokensOwned", headerName: "Marines & Aliens" },
  // { width: 150, field: "numAllTokens", headerName: "All Tokens" },
  // { width: 150, field: "founderPassOwned", headerName: "Founder Pass" },
  //width: 100,  { field: "founderPassMinted", headerName: "Founder Pass Minted" },

  { width: 100, field: "marinesOwned", headerName: "Marines Owned" },
  { width: 100, field: "aliensOwned", headerName: "Aliens Owned" },
  { width: 100, field: "spidoxOwned", headerName: "Spid0x Owned" },

  // { field: "marinesStaked", headerName: "Marines Staked" },
  // { field: "aliensStaked", headerName: "Aliens Staked" },

  { field: "marinesStolen", headerName: "Marines Stolen" },
  // { field: "aliensStolen", headerName: "Aliens Stolen" },

  { field: "marinesLost", headerName: "Marines Lost" },
  // { field: "aliensLost", headerName: "Aliens Lost" },

  { field: "marinesMinted", headerName: "Marines Minted" },
  { field: "aliensMinted", headerName: "Aliens Minted" },
];

/**
 * Simulates server data loading
 */
interface RowsState {
  page: number;
  pageSize: number;
  rows: GridRowModel[];
  loading: boolean;
}

function truncate(str: string, len: number) {
  return str.length > len + 3 ? `${str.slice(0, len)}...` : str;
}

export const PlayerDataGrid = (): JSX.Element => {
  const { traits, loadTraits } = useTraits();
  loadTraits && loadTraits(true);

  const [rowsState, setRowsState] = React.useState<RowsState>({
    page: 0,
    pageSize: 50,
    rows: [{ id: "Loading..." }],
    loading: false,
  });

  const navigate = useNavigate();

  const goAddress = (params: GridCellParams) => {
    const { row } = params;

    navigate(`/addresses/${row.address}`);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      setRowsState((prev) => ({ ...prev, loading: true }));
      const result = await client.query({
        query: TOP_PLAYERS,
        variables: {
          skip: rowsState.page * rowsState.pageSize,
          count: rowsState.pageSize,
        },
      });

      let newRows = result?.data?.players ?? [];
      const idx = newRows.findIndex((row) => {
        return row.id === mnaContract;
      });

      newRows = newRows.map((row: any) => {
        const numFp = parseInt(row.founderPassOwned, 10);
        const numTokens = parseInt(row.numTokensOwned, 10);

        return {
          ...row,
          id: truncate(row.id, 22),
          address: row.id,
          numTokensOwned: numTokens,
          numAllTokens: numFp + numTokens,
          founderPassMinted: parseInt(row.founderPassMinted, 10),
          founderPassOwned: numFp,

          aliensOwned: parseInt(row.aliensOwned, 10),
          aliensMinted: parseInt(row.aliensMinted, 10),
          aliensStaked: parseInt(row.aliensStaked, 10),
          aliensStolen: parseInt(row.aliensStolen, 10),
          marinesOwned: parseInt(row.marinesOwned, 10),
          marinesMinted: parseInt(row.marinesMinted, 10),
          marinesStaked: parseInt(row.marinesStaked, 10),
          marinesStolen: parseInt(row.marinesStolen, 10),
          displayName: row.id === mnaContract ? 'Unclaimed Marines & Aliens' : truncate(row.id, 22),
        };
      });

      if (!active) {
        return;
      }

      setRowsState((prev) => ({ ...prev, loading: false, rows: newRows }));
    })();

    return () => {
      active = false;
    };
  }, [rowsState.page, rowsState.pageSize]);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          {...rowsState}
          rowCount={2568} // TODO Player Count
          columns={columns}
          // Features
          pagination
          paginationMode="server"
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          isCellEditable={() => false}
          isRowSelectable={() => false}
          // Styling
          density="standard"
          scrollbarSize={3}
          // Handlers
          onCellClick={goAddress}
          onPageChange={(page) => setRowsState((prev) => ({ ...prev, page }))}
          onPageSizeChange={(pageSize) =>
            setRowsState((prev) => ({ ...prev, pageSize }))
          }
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
};

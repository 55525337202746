import {
  Box,
  Button,
  Grid,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Typography,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Link } from "react-router-dom";

const donationAddress = "0x80d8a731a0b68a22e72b89c4032e67f5fc78c1bc";

export const Footer = () => {
  return (
    <Box
      sx={{
        margin: "0 auto",
        position: "relative",
        width: "100%",
        bottom: "0",
        backgroundImage: "url(/shapes.svg)",
        backgroundColor: "#081d30",
        height: "6vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        sx={{
          maxWidth: "1400px",
          padding: "0 3vw",
        }}
      >
        <Grid
          item
          xs={3}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Typography
            variant="body2"
            color="white"
            sx={{ paddingRight: ".3rem" }}
          >
            Made with
          </Typography>
          <FavoriteIcon color="primary" />
          <Typography
            variant="body2"
            color="white"
            sx={{ paddingLeft: ".3rem" }}
          >
            by...
          </Typography>
        </Grid>
        <Grid container item xs={9} justifyContent="flex-end">
          <Box
            sx={{
              backgroundColor: "white",
              padding: "0 1rem",
              display: "flex",
              alignItems: "center",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            <span>{donationAddress}</span>
          </Box>
          <Tooltip title="Copy Address">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              disableElevation
              sx={{
                marginLeft: "-3px",
              }}
              onClick={() => navigator.clipboard.writeText(donationAddress)}
            >
              Send $ORES
            </Button>
          </Tooltip>
          <a href="https://github.com/elkdao" target="_blank" rel="noreferer">
            <Tooltip title="GitHub">
              <Button
                size="small"
                variant="outlined"
                sx={{ marginLeft: "1rem" }}
              >
                <GitHubIcon />
              </Button>
            </Tooltip>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";
import {
  AppBar,
  Box,
  Drawer,
  Grid,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Terminal } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useState } from "react";

import { Console } from "views/Console";
import { Search } from "views/Search";
import ores from "assets/ores.png";

const ToolbarWrapper = styled("div")(({ theme }) => ({
  maxWidth: "1400px",
  width: "100%",
}));

const TitleLanding = (): JSX.Element => {
  return (
    <Grid container direction="row" alignItems="center" item xs={4}>
      <Link to="/">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img src={ores} style={{ height: "1.3rem", marginRight: ".3rem" }} />
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              color: "black",
            }}
          >
            spacegamescan
          </Typography>
        </Box>
      </Link>
    </Grid>
  );
};

const TitleHeader = (): JSX.Element => {
  return (
    <Grid container item xs={4} direction="row" alignItems="center">
      <Link to="/">
        <Grid container>
          <Grid container item direction="row" alignItems="center" xs={12}>
            <img
              src={ores}
              style={{ height: "1.3rem", marginRight: ".3rem" }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "black",
              }}
            >
              spacegamescan
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              borderRadius: "4px",
              backgroundColor: "#f8f9fa",
              padding: "1px .5rem 1px",
            }}
          >
            <Typography variant="caption" sx={{ color: "black" }}>
              ORES: $????
            </Typography>
            <Typography variant="caption" sx={{ marginLeft: "3px" }}>
              (+????%)
            </Typography>{" "}
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
};

type SearchProps = {
  drawerOpen: boolean;
  openDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchLanding = (props: SearchProps): JSX.Element => {
  const { drawerOpen, openDrawer } = props;
  return (
    <Grid container item xs={8} justifyContent="flex-end">
      <Tooltip title="Console">
        <IconButton
          className="fuck"
          size="large"
          edge="start"
          color="inherit"
          onClick={() => openDrawer(!drawerOpen)}
        >
          <Terminal sx={{ fontSize: 28 }} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const SearchHeader = (props: SearchProps): JSX.Element => {
  const { drawerOpen, openDrawer } = props;
  return (
    <Grid
      container
      item
      xs={8}
      direction="column"
      alignItems="flex-end"
      sx={{
        paddingTop: ".5rem",
      }}
    >
      <Search maxWidth="500px" />
      <Tooltip title="Console">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ mr: 0, padding: "0 0 12px 0" }}
          onClick={() => openDrawer(!drawerOpen)}
        >
          <Terminal sx={{ fontSize: 28 }} />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

const addressRegex = /\/addresses\/[A-Za-z0-9]+/;

export const AppHeader = (): JSX.Element => {
  const [drawerOpen, openDrawer] = useState(false);
  const { pathname } = useLocation();

  const isAddressView = addressRegex.test(pathname);
  const props = { drawerOpen, openDrawer };
  const right = isAddressView ? (
    <SearchHeader {...props} />
  ) : (
    <SearchLanding {...props} />
  );

  const left = isAddressView ? <TitleHeader /> : <TitleLanding />;

  const closeDrawer = () => {
    openDrawer(false);
  };

  return (
    <AppBar
      position="static"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        boxShadow: 0,
      }}
    >
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={closeDrawer}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: "rgb(33, 33, 33)",
            borderRadius: "4px",
          },
        }}
      >
        <Console />
      </Drawer>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: "0 3vw",
          maxWidth: "1400px",
          width: "100%",
        }}
      >
        {left}
        {right}
      </Grid>
    </AppBar>
  );
};

import React, { useState } from "react";
import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { bigIntSort } from "utils";
import { useTraits } from "hooks/useTraits";
import { getRarityScore, getRarityPercentile } from "utils/rarity";

type Props = {
  tokens: any[]; // TODO
  setTokenId: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
};

const columns: GridColDef[] = [
  {
    width: 75,
    field: "image",
    headerName: "Image",
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ height: 50, width: 50 }}>
        <img src={params.value} />
      </Box>
    ),
  },
  { width: 100, field: "tokenId", headerName: "Token ID" },
  { width: 100, field: "rarityScore", headerName: "RScoreᵇᵉᵗᵃ" },
  // { width: 100, field: "rarityPercentile", headerName: "Rarity" },
  { width: 75, field: "typ", headerName: "Name" },
  { width: 125, field: "weaponMouth", headerName: "Weapon / Mouth" },
  { width: 125, field: "headgear", headerName: "Head" },
  { width: 125, field: "body", headerName: "Body" },
  { width: 125, field: "back", headerName: "Back" },
  { width: 125, field: "eyes", headerName: "Eye" },
  { width: 125, field: "emblem", headerName: "Emblem" },
  { width: 75, field: "rank", headerName: "Rank" },
  { width: 75, field: "isStaked", headerName: "Staked" },
  { width: 125, field: "oresEarned", headerName: "Ores Earned" },
  //width: 150,  { field: "thief", headerName: "Thief" },
  //width: 150,  { field: "victim", headerName: "Victim" },
  { width: 100, field: "mintedAt", headerName: "Minted At" },
  //width: 150,  { field: "mintBlock", headerName: "Block" },
  { width: 250, field: "mintTx", headerName: "Mint Transaction" },
];

function calculateOresEarned(token: any) {
  return 0;
}

function nA(field: string | null) {
  if (field == null || field.length === 0) {
    return "n / a";
  }

  return field;
}

function truncate(str: string, len: number) {
  return str.length > len + 3 ? `${str.slice(0, len)}...` : str;
}

export const Details = (props: Props): JSX.Element => {
  const { setTokenId, tokens, loading } = props;
  const sorted = [...(tokens ?? [])].sort(bigIntSort("tokenId"));
  let rows = [{ id: "loading", tokenId: "Loading..." }];

  const { traits, loaded: loadedTraits } = useTraits();

  if (!loading && loadedTraits) {
    rows = sorted.map((token) => {
      const rank = parseInt(token["rank score"], 10);
      const rankScore = Number.isNaN(rank) ? undefined : rank;

      return {
        ...token,
        tokenId: parseInt(token.tokenId, 10),
        "rank score": rankScore,
        isStaked: parseInt(token.stakedAt, 10) > 0,
        oresEarned: calculateOresEarned(token),
        thief: nA(token.thief?.id),
        victim: nA(token.victim?.id),
        mintTx: truncate(token.mintTx, 28),
        rarityScore: getRarityScore(traits, token),
        rarityPercentile: `${getRarityPercentile(traits, token)} %`,
      };
    });
  }

  const onGetToken = (params: GridCellParams) => {
    const { field, row } = params;

    setTokenId(row.tokenId);
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "50vh",
        maxHeight: "100vh",
        marginTop: "6vh",
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          // Features
          pagination
          paginationMode="server"
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          isCellEditable={() => false}
          isRowSelectable={() => false}
          // Styling
          density="standard"
          scrollbarSize={3}
          // Handlers
          onCellClick={onGetToken}
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
};

import { Grid } from "@mui/material";

import { AddressOverview, OverviewPane, TokenOverview } from "views/address";

type Props = {
  address: string;
  token: any | null;
  player: any;
};

export const Overview = (props: Props): JSX.Element => {
  const { address, player, token } = props;
  const tokenTitle = token
    ? `Token ${token.tokenId}`
    : "Please select a token below";

  return (
    <Grid
      className="overview"
      container
      sx={{
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <OverviewPane title={`Player ${address}`} sx={{ marginRight: ".5rem" }}>
        <AddressOverview player={player} />
      </OverviewPane>
      <OverviewPane title={tokenTitle} sx={{ marginLeft: ".5rem" }}>
        <TokenOverview token={token} />
      </OverviewPane>
    </Grid>
  );
};

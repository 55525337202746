import React from "react";
import { Box } from "@mui/material";
import Terminal from "react-console-emulator";
import { client } from "clients/apolloClient";

import { top, crate } from "utils/console";
import { player, playerTokens, token } from "queries";
import { mnaContract } from "utils/constants";

const welcome =
  "Welcome to Space Game Scan!\nType 'help' for a list of commands.";

const founderPassContract = "0xf4a57dac3d3a4772347f813c6bf52b6286ac649e";
export class Console extends React.Component {
  terminal: React.Ref<Console>;
  constructor(props: any) {
    super(props);
    this.terminal = React.createRef();
  }

  commands = {
    aliens: {
      description: "top # of aliens <owned, minted, staked, stolen, lost>",
      usage: "aliens owned",
      fn: top.bind(this, "aliens"),
    },
    marines: {
      description: "top # of marines <owned, minted, staked, stolen, lost>",
      usage: "marines minted",
      fn: top.bind(this, "marines"),
    },
    tokens: {
      description: "top # of tokens <owned, minted, staked, stolen, lost>",
      usage: "tokens staked",
      fn: top.bind(this, "tokens"),
    },
    flags: {
      description: "The 3 commands above accept the following optional flags",
      usage:
        "\n-n <number> (number of results)\n-s <number> (number of results to skip for pagination)\n-m (fetch tokens with metadata)\n",
      fn: () => {
        return "Invalid command";
      },
    },
    token: {
      description: "token <tokenId>",
      usage: "token 1234",
      fn: (tokenId: string) => {
        const term = (this as any)?.terminal?.current;
        const id = parseInt(tokenId, 10);
        if (Number.isNaN(id)) {
          return "Invalid tokenId";
        }

        client
          .query({
            query: token,
            variables: {
              tokenId: `${mnaContract}-${tokenId}`,
            },
          })
          .then((result) => {
            const token = result?.data?.token;
            if (token == null) {
              term.pushToStdout(`No token with id ${tokenId}`);
              return;
            }

            const { __typename, ...rest } = token;
            const { image, ...metadata } = token?.metadata ?? {};
            const t = {
              ...rest,
              owner: rest.owner.id,
            };
            term.pushToStdout(JSON.stringify(t, null, 2));
          });
      },
    },
    player: {
      description: "player <address> - player 0x123456",
      usage: "\n player tokens <address> - player tokens 0x123456",
      fn: (addressTokens: string, address: string) => {
        // get a terminal
        const term = (this as any)?.terminal?.current;
        if (addressTokens == null) {
          return "Invalid address";
        }

        if (addressTokens === "tokens") {
          if (address == null) {
            return "Invalid address";
          }
          client
            .query({
              query: playerTokens,
              variables: { address: address.toLowerCase() },
            })
            .then((result: any) => {
              let out = `No player found for address ${address}`;
              if (result.data?.player != null) {
                const { tokens } = result.data?.player;
                out = tokens.map((p: any) => p.tokenId).join("\n");
              }
              term.pushToStdout(out);
            });
        } else {
          client
            .query({
              query: player,
              variables: { address: addressTokens.toLowerCase() },
            })
            .then((result: any) => {
              let out = `No player found for address ${address}`;
              if (result.data?.player != null) {
                const { __typename, ...player } = result.data.player;
                out = JSON.stringify(player, null, 2);
              }
              term.pushToStdout(out);
            });
        }
      },
    },
    crate: {
      description: "Check FounderPass claim status",
      usage: "crate <tokenId>",
      fn: crate.bind(this),
    },
  };

  render() {
    return (
      <Box sx={{ width: "100%" }}>
        <Terminal
          ref={this.terminal}
          welcomeMessage={welcome}
          autoFocus={true}
          commands={this.commands}
          className="sg-terminal"
        />
      </Box>
    );
  }
}

import { ApolloProvider } from "@apollo/client";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import {
  AppHeader,
  Footer,
  LandingContainer,
  ResultContainer,
} from "views/layout";
import "./App.css";
import { client } from "clients/apolloClient";
import { theme } from "./theme";

const addressRegex = /\/addresses\/[A-Za-z0-9]+/;
import { useLocation } from "react-router-dom";

const Content = (): JSX.Element => {
  const { pathname } = useLocation();
  const isAddressView = addressRegex.test(pathname);

  return (
    <Box
      className="wrapper"
      sx={{
        backgroundColor: isAddressView ? "#f8f9fa" : "#fff",
        height: "100vh",
        maxHeight: "100vh",
        overflowY: "auto",
      }} // TODO use theme colors
    >
      <AppHeader />
      <Box
        className="container"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Routes>
          <Route path="/" element={<LandingContainer />} />
          <Route path="/addresses/:id" element={<ResultContainer />} />
        </Routes>
      </Box>
      <Box sx={{ height: "9vh", display: "block" }}></Box>
      <Footer />
    </Box>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <Router>
          <Content />
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;

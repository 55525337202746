import { Divider, Box, List } from "@mui/material";

import { TopPlayer, TopPlayerSkeleton } from "./index";
import { bigIntSort } from "utils";

// TODO ANY
type Props = {
  players?: any[];
  loading: boolean;
};

const placeholder = Array.from({ length: 10 }).map((i: any, index: number) => (
  <Box key={`skeleton-${index}`}>
    <TopPlayerSkeleton />
    {index < 9 ? <Divider component="li" /> : null}
  </Box>
));

export const TopPlayers = (props: Props): JSX.Element => {
  const { loading, players } = props;

  const topPlayers = [...(players ?? [])];

  const content = loading
    ? placeholder
    : topPlayers.sort(bigIntSort("founderPassOwned")).map((player, index) => (
        <Box key={player.id}>
          <TopPlayer player={player} />
          {index < topPlayers.length - 1 ? <Divider component="li" /> : null}
        </Box>
      ));

  return (
    <div
      role="tabpanel"
      className="scrollable"
      style={{
        maxHeight: "45vh",
        width: "100%",
      }}
    >
      <List sx={{ padding: "0" }}>{content}</List>
    </div>
  );
};

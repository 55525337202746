import { Button, Box, Modal, TextField, Typography } from "@mui/material";
import { DAppProvider, Polygon, useEthers } from "@usedapp/core";
import { useState } from "react";

type Props = {
  open: boolean;
  onClose: any;
};

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const config = {
  readOnlyChainId: Polygon.chainId,
  readOnlyUrls: {
    [Polygon.chainId]:
      "https://polygon-mainnet.g.alchemy.com/v2/K6v9Dzv2mz06twD2FU86FupF-D5xFVWT",
  },
  networks: [Polygon],
};

const generateMessage = (
  account: string,
  name: string,
  twitter: string
): string => {
  if (account.length === 0) {
    throw new Error("Invalid account");
  }

  if (name.length === 0) {
    throw new Error("Invalid display name");
  }

  return `spacegamescan.xyz claim:\n${account}:${name}:${twitter}`;
};

async function validate(
  event: React.MouseEvent,
  ethers: any,
  account: string,
  name: string,
  twitter: string,
  onFinish: any
) {
  event.preventDefault();
  const signer = ethers.getSigner();
  const msg = generateMessage(account, name, twitter);
  const signature = await signer.signMessage(msg);
  console.log(signature);
  onFinish();
}

export const ModalContent = (props: Props): JSX.Element => {
  const [name, setName] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");

  const { activateBrowserWallet, account, library } = useEthers();
  const { open, onClose } = props;

  const onConnect = (event: React.MouseEvent) => {
    event.preventDefault();
    activateBrowserWallet();
  };

  const closeModal = () => {
    onClose();
  };

  const connect = (
    <Button variant="contained" onClick={onConnect}>
      Connect
    </Button>
  );

  const onNameChange = (event: any) => {
    event.preventDefault();
    const val = event?.target?.value ?? "";
    setName(val);
  };

  const onTwitterChange = (event: any) => {
    event.preventDefault();
    const val = event?.target?.value ?? "";
    setTwitter(val);
  };

  const inputs = (
    <Box>
      <Box>
        <TextField
          variant="filled"
          label="Display Name"
          value={name}
          onChange={onNameChange}
          sx={{ width: "25vw" }}
        />
      </Box>
      <br />
      <Box>
        <TextField
          variant="filled"
          label="Twitter Handle (optional)"
          value={twitter}
          onChange={onTwitterChange}
          sx={{ width: "25vw" }}
        />
      </Box>
    </Box>
  );

  const sign = (
    <Box>
      <br />
      <Button
        variant="contained"
        onClick={(event) =>
          validate(event, library, account ?? "", name, twitter, closeModal)
        }
      >
        Confirm
      </Button>
    </Box>
  );

  return (
    <Modal open={open} onClose={closeModal}>
      <Box sx={modalStyle}>
        <Typography>
          Claiming this wallet will allow you display a custom name instead of
          your wallet address as well as an optional link to your Twitter.
        </Typography>
        <br />
        <Typography>
          This process will involve connecting your wallet then signing a
          message so that we can verify ownership.
        </Typography>
        <br />
        {account && account.length > 1 ? inputs : connect}
        {account && account.length > 1 && name.length > 1 ? sign : null}
      </Box>
    </Modal>
  );
};

export const ClaimAddressModal = (props: Props): JSX.Element => {
  return (
    <DAppProvider config={config}>
      <ModalContent {...props} />
    </DAppProvider>
  );
};

import { Box, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import { useState } from "react";
import { ClaimAddressModal } from "views/layout";

const playerProps = [
  // { field: "mints", name: "Gen-1+ Mints" },
  { field: "numTokensOwned", name: "Marines & Aliens" },
  { field: "aliensOwned", name: "Aliens" },
  { field: "marinesOwned", name: "Marines" },
  { field: "spidoxOwned", name: "Spid0x" },
  // { field: "aliensMinted",
  // { field: "marinesMinted",
  // { field: "aliensLost",
  // { field: "aliensStaked",
  // { field: "aliensStolen",
  // { field: "marinesLost",
  // { field: "marinesStaked",
  // { field: "marinesStolen",
  { field: "founderPassOwned", name: "Crates Owned" },
  { field: "founderPassMinted", name: "Crates Minted" },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

type Props = {
  player: any; // TODO
};

function createRows(player: any) {
  if (player == null) {
    return [1, 2, 3, 4, 5];
  }

  const rows = playerProps.map((prop) => {
    const parsed = parseInt(player[prop.field], 10);

    return {
      field: prop.field,
      name: prop.name,
      value: Number.isNaN(parsed) ? player[prop.field] : parsed,
    };
  });

  return rows;
}

const renderRow = (row: any, playerId: string): JSX.Element => {
  if (typeof row === "number") {
    return renderPlaceholderRow(row);
  }
  return renderTokenRow(row, playerId);
};

const renderTokenRow = (row: any, playerId: string): JSX.Element => {
  return (
    <StyledTableRow key={`${playerId}-${row.name}`}>
      <StyledTableCell component="th" scope="row">
        {row.name}
      </StyledTableCell>
      <StyledTableCell align="right">{row.value}</StyledTableCell>
    </StyledTableRow>
  );
};

const renderPlaceholderRow = (row: any): JSX.Element => {
  return (
    <StyledTableRow key={`placeholder-${row}`}>
      <StyledTableCell component="th" scope="row">
        <Skeleton />
      </StyledTableCell>
      <StyledTableCell align="right">
        <Skeleton />
      </StyledTableCell>
    </StyledTableRow>
  );
};

const claim = (address: string, setOpen: any): JSX.Element => {
  return (
    <StyledTableRow
      key={`${address ?? "missing"}-claim-me`}
      sx={{
        cursor: "pointer",
      }}
      onClick={() => setOpen(true)}
    >
      <StyledTableCell
        component="th"
        scope="row"
        sx={{
          fontWeight: "700",
        }}
      >
        Claim This Address!
      </StyledTableCell>
      <StyledTableCell align="right"></StyledTableCell>
    </StyledTableRow>
  );
};

export const AddressOverview = (props: Props): JSX.Element => {
  const { player } = props;
  const rows = createRows(player);

  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  return (
    <TableContainer
      component={Box}
      sx={{ overflowY: "auto", height: "100%", maxHeight: "22vh" }}
    >
      <ClaimAddressModal open={open} onClose={toggle} />
      <Table sx={{ width: "100%" }} aria-label="customized table">
        <TableBody>
          {rows.map((row: any) => renderRow(row, player?.id ?? ""))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

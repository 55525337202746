import React from "react";
import { useState, useContext, useEffect, createContext } from "react";
import { gql } from "@apollo/client";

import { client } from "clients/pending";

type Trait = {
  tokenTyp: string;
  typ: string;
  name: string;
  count: number;
  rarity: number;
};

export type Traits = {
  [name: string]: {
    [name: string]: Trait;
  };
};

type IC = {
  traits: Traits;
  loadTraits: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  loaded: boolean;
};

const initialTraits = {};
const initialContext = {
  traits: initialTraits,
  loaded: false,
};
const TraitsContext = createContext<Partial<IC>>(initialContext);

type Props = {
  children: React.ReactNode;
};

const GET_TRAITS = gql`
  query getTraits($skip: Int!) {
    traits(first: 100, skip: $skip) {
      tokenTyp
      typ
      name
      count
      rarity
    }
  }
`;

const traits = {};
async function fetchTraits(count: number) {
  const result = await client.request(GET_TRAITS, { skip: count });
  const newTraits = result.traits ?? [];

  for (const t of newTraits) {
    let nested = traits[t.tokenTyp];
    if (nested == null) {
      nested = {};
      traits[t.tokenTyp] = nested;
    }

    nested[t.name] = {
      ...t,
      rarity: parseFloat(t.rarity),
    };
  }

  if (newTraits.length === 100) {
    return fetchTraits(count + 100);
  }

  return traits;
}

export const TraitsProvider = (props: Props): JSX.Element => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function getTraits() {
      await fetchTraits(0);

      setLoaded(true);
    }

    getTraits();
  }, []);

  return (
    <TraitsContext.Provider value={{ traits, loaded }}>
      {props.children}
    </TraitsContext.Provider>
  );
};

export const useTraits = () => {
  return useContext(TraitsContext);
};

import { Box, Button, Card } from "@mui/material";
import { gql, useQuery } from "@apollo/client";

import { TopPlayers } from "views/game";

const TOP_PLAYERS = gql`
  {
    players(first: 20, orderBy: founderPassOwned, orderDirection: desc) {
      id
      founderPassOwned
      founderPassMinted
    }
  }
`;

export const TopPlayersContainer = (): JSX.Element => {
  const { loading, data, error } = useQuery(TOP_PLAYERS);
  const fpHoarders = data?.players ?? [];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        maxHeight: "50vh",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
          borderRadius: "4px",
          height: "50%",
          width: "49.5%",
        }}
      >
        <Box
          sx={{
            padding: "9px",
          }}
        >
          Most Aliens
        </Box>
        <Box
          sx={{
            borderTop: "1px solid",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <TopPlayers loading={loading} players={fpHoarders} />
        </Box>
        <Box sx={{ padding: "9px" }}>
          <Button
            variant="contained"
            disabled
            disableElevation
            sx={{
              width: "100%",
            }}
          >
            View Top Thieves
          </Button>
        </Box>
      </Card>

      <Card
        variant="outlined"
        sx={{
          boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
          borderRadius: "4px",
          height: "100%",
          width: "49.5%",
        }}
      >
        <Box
          sx={{
            padding: "9px",
          }}
        >
          Most Marines
        </Box>
        <Box
          sx={{
            borderTop: "1px solid",
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <TopPlayers loading={loading} players={fpHoarders} />
        </Box>
        <Box sx={{ padding: "9px" }}>
          <Button
            variant="contained"
            disabled
            disableElevation
            sx={{
              width: "100%",
            }}
          >
            View Top Victims
          </Button>
        </Box>
      </Card>
    </Box>
  );
};

import { client } from "clients/apolloClient";
import {
  topAliens,
  topAliensMeta,
  topMarines,
  topMarinesMeta,
  topTokens,
  topTokensMeta,
  getFp,
} from "queries";
import { mnaContract, fpContract } from "utils/constants";
import { client as sgfinderClient } from "clients/sgfinder";

export async function crate(this: any, tokenId: string) {
  const term = (this as any)?.terminal?.current;
  const parsed = parseInt(tokenId, 10);
  if (Number.isNaN(parsed)) {
    return `Invalid tokenId: ${tokenId}`;
  }

  const id = `${fpContract}-${tokenId}`;
  sgfinderClient.request(getFp, { id }).then((result) => {
    const { fptoken } = result;
    if (fptoken) {
      term.pushToStdout(JSON.stringify(fptoken, null, 2));
    } else {
      term.pushToStdout(`Could not find token: ${tokenId}`);
    }
  });
}

const aliens = {
  meta: topAliensMeta,
  top: topAliens,
};

const marines = {
  meta: topMarinesMeta,
  top: topMarines,
};

const tokens = {
  meta: topTokensMeta,
  top: topTokens,
};

const queries = {
  aliensOwned: aliens,
  aliensMinted: aliens,
  aliensStolen: aliens,
  aliensLost: aliens,
  aliensStaked: aliens,
  marinesOwned: marines,
  marinesMinted: marines,
  marinesStolen: marines,
  marinesLost: marines,
  marinesStaked: marines,
  numTokensOwned: tokens,
  numTokensMinted: tokens,
  numTokensStolen: tokens,
  numTokensLost: tokens,
  numTokensStaked: tokens,
};

export async function top(this: any, ...args: any[]) {
  // get a terminal
  const term = (this as any)?.terminal?.current;

  const options = commandParser(args);
  if (typeof options === "string") {
    return options;
  }
  console.log(options);

  const field = options?.field;
  switch (field) {
    case "aliensOwned":
    case "aliensMinted":
    case "aliensStolen":
    case "aliensLost":
    case "aliensStaked":
    case "marinesOwned":
    case "marinesMinted":
    case "marinesStolen":
    case "marinesLost":
    case "marinesStaked":
    case "tokensOwned":
    case "tokensMinted":
    case "tokensStolen":
    case "tokensLost":
    case "tokensStaked":
      getTop(options, term);
      break;
    default:
      return invalid();
  }
}

// todo type
async function getTop(options: any, terminal: any) {
  const { field, count, skip, getMeta } = options;
  let f = field as string;

  // prepend num to token fields
  if (f.includes("token")) {
    f = `num${f.charAt(0).toUpperCase()}${f.slice(1, f.length)}`;
  }
  const q = queries[f];

  const promise = client
    .query({
      query: getMeta ? queries[f].meta : queries[f].top,
      variables: {
        field: f,
        skip,
        count,
        contract: mnaContract,
      },
    })
    .then((result) => {
      const players = (result?.data?.players ?? []).map((p: any) => {
        const tokens = p.tokens.map((t: any) => {
          if (getMeta) {
            const { image, metadata } = t.metadata ?? {};
            return {
              tokenId: t.tokenId,
              metadata,
            };
          } else {
            return t.tokenId;
          }
        });

        return {
          ...stripTypename(p),
          tokens,
        };
      });

      terminal.pushToStdout(JSON.stringify(players, null, 2));
    });
}

function stripTypename(obj: any): any {
  const { __typename, ...rest } = obj;
  return {
    ...rest,
  };
}

const DEFAULT_COUNT = 25;
const rexCount = /-n (\d+)/;
const rexMeta = /-(m)/;
const rexSkip = /-s (\d+)/;

function commandParser(commands: string[]) {
  if (commands.length < 2) {
    return invalid();
  }

  const cmds = [...commands];
  const first = cmds.shift();
  const second = cmds.shift();

  if (typeof second !== "string") {
    return invalid();
  }
  const field = `${first}${second.charAt(0).toUpperCase()}${second.slice(
    1,
    second.length
  )}`;

  let count = DEFAULT_COUNT;
  const options = cmds.join(" ");
  const countMatch = options.match(rexCount);
  if (countMatch != null) {
    const newCount = parseInt(countMatch[1]);
    if (typeof newCount === "number" && !Number.isNaN(newCount)) {
      if (newCount > 100) {
        return "Count cannot be > 100. Use skip (-s) to paginate";
      }
      count = newCount;
    } else {
      return invalid();
    }
  }

  let skip = 0;
  const skipMatch = options.match(rexSkip);
  if (skipMatch != null) {
    const newSkip = parseInt(skipMatch[1]);
    if (typeof newSkip === "number" && !Number.isNaN(newSkip)) {
      skip = newSkip;
    } else {
      return invalid();
    }
  }

  let getMeta = false;
  if (rexMeta.test(options)) {
    getMeta = true;
  }

  return {
    field,
    count,
    skip,
    getMeta,
  };
}

function invalid() {
  return "Invalid command";
}

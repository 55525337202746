import { gql } from "@apollo/client";

export const topAliens = gql`
  query top($contract: String!, $field: String!, $count: Int!, $skip: Int!) {
    players(first: $count, skip: $skip, orderBy: $field, orderDirection: desc) {
      id
      aliensOwned
      aliensMinted
      aliensStolen
      aliensLost
      aliensStaked
      numTokensOwned
      numTokensStaked
      numTokensStolen
      numTokensLost
      tokens(where: { contract: $contract, typ: "Alien" }) {
        tokenId
      }
    }
  }
`;

export const topAliensMeta = gql`
  query top($contract: String!, $field: String!, $count: Int!, $skip: Int!) {
    players(first: $count, skip: $skip, orderBy: $field, orderDirection: desc) {
      id
      aliensOwned
      aliensMinted
      aliensStolen
      aliensLost
      aliensStaked
      numTokensOwned
      numTokensStaked
      numTokensStolen
      numTokensLost
      tokens(where: { contract: $contract, typ: "Alien" }) {
        tokenId
        metadata
      }
    }
  }
`;

export const topMarines = gql`
  query top($contract: String!, $field: String!, $count: Int!, $skip: Int!) {
    players(first: $count, skip: $skip, orderBy: $field, orderDirection: desc) {
      id
      marinesOwned
      marinesMinted
      marinesStolen
      marinesLost
      marinesStaked
      numTokensOwned
      numTokensStaked
      numTokensStolen
      numTokensLost
      tokens(where: { contract: $contract, typ: "Marines" }) {
        tokenId
      }
    }
  }
`;

export const topMarinesMeta = gql`
  query top($contract: String!, $field: String!, $count: Int!, $skip: Int!) {
    players(first: $count, skip: $skip, orderBy: $field, orderDirection: desc) {
      id
      marinesOwned
      marinesMinted
      marinesStolen
      marinesLost
      marinesStaked
      numTokensOwned
      numTokensStaked
      numTokensStolen
      numTokensLost
      tokens(where: { contract: $contract, typ: "Marines" }) {
        tokenId
        metadata
      }
    }
  }
`;

export const topTokens = gql`
  query top($contract: String!, $field: String!, $count: Int!, $skip: Int!) {
    players(first: $count, skip: $skip, orderBy: $field, orderDirection: desc) {
      id
      numTokensOwned
      numTokensStaked
      numTokensStolen
      numTokensLost
      tokens(where: { contract: $contract }) {
        tokenId
      }
    }
  }
`;

export const topTokensMeta = gql`
  query top($contract: String!, $field: String!, $count: Int!, $skip: Int!) {
    players(first: $count, skip: $skip, orderBy: $field, orderDirection: desc) {
      id
      numTokensOwned
      numTokensStaked
      numTokensStolen
      numTokensLost
      tokens(where: { contract: $contract }) {
        tokenId
        metadata
      }
    }
  }
`;

export const topFoundersOwned = gql`
  query topFoundersOwned($limit: Int!) {
    players(first: $limit, orderBy: founderPassOwned, orderDirection: desc) {
      id
      founderPassOwned
    }
  }
`;

export const topFoundersMinted = gql`
  query topFoundersOwned($limit: Int!) {
    players(first: $limit, orderBy: founderPassMinted, orderDirection: desc) {
      id
      founderPassMinted
    }
  }
`;

export const player = gql`
  query player($address: String!) {
    player(id: $address) {
      id
      founderPassOwned
      founderPassMinted
      mints
      numTokensOwned
      aliensOwned
      marinesOwned
      aliensMinted
      marinesMinted
      aliensLost
      aliensStaked
      aliensStolen
      marinesLost
      marinesStaked
      marinesStolen
    }
  }
`;

export const playerTokens = gql`
  query playerTokens($address: String!) {
    player(id: $address) {
      id
      tokens {
        tokenId
      }
    }
  }
`;

export const token = gql`
  query playerToken($tokenId: String!) {
    token(id: $tokenId) {
      contract
      tokenId
      typ
      owner {
        id
      }
      mintBlock
      mintedAt
      mintTx
      metadata
    }
  }
`;

export const getFp = gql`
  query getFP($id: String!) {
    fptoken(id: $id) {
      tokenId
      owner {
        id
      }
      claimed
      mintTx
    }
  }
`;

import type { Traits } from "hooks/useTraits";

const commonFields = ["weaponMouth", "headgear", "body", "back", "eyes"];
const marineFields = [...commonFields, "emblem"];
const alienFields = [...commonFields, "rank"];

const rexAlien = /Alien/;
export const getRarityScore = (
  traits: Traits | undefined,
  token: any
): number => {
  if (traits == null) {
    return 0;
  }
  const tokenTraits = traits[token.typ];
  if (tokenTraits == null) {
    return 0;
  }

  let fields = rexAlien.test(token.typ) ? alienFields : marineFields;

  let score = 0;
  for (const field of fields) {
    const trait = tokenTraits[token[field]];
    if (trait == null) {
      // return 0;
    } else {
      const rarity = trait.rarity * 100;
      score = score + 100 - rarity;
    }
  }

  return Math.floor(score);
};

export const getRarityPercentile = (
  traits: Traits | undefined,
  token: any
): string => {
  if (traits == null) {
    return "0";
  }
  const tokenTraits = traits[token.typ];
  if (tokenTraits == null) {
    return "0";
  }

  let fields = rexAlien.test(token.typ) ? alienFields : marineFields;

  let score = 0;
  for (const field of fields) {
    const trait = tokenTraits[token[field]];
    if (trait == null) {
      // return 0;
    } else {
      const rarity = trait.rarity;
      score = score + rarity;
    }
  }

  return ((score / fields.length) * 100).toFixed(2);
};

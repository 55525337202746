import { Box, Typography } from "@mui/material";

import logoLg from "assets/logo-large.png";
import { Search } from "views/Search";

export const LandingSearchContainer = (): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "0 3vw",
        maxWidth: "1400px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 2,
          paddingRight: "3vw",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            margin: "1rem 0",
            color: "white", // TODO
          }}
        >
          Space Game Explorer
        </Typography>
        <Search maxWidth="50vw" />
      </Box>
      <Box>
        <img
          src={logoLg}
          alt="Logo"
          style={{
            width: "250px",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

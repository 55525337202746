import {
  Box,
  Grid,
  Chip,
  Divider,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import { useTraits } from "hooks/useTraits";
import { getRarityScore, getRarityPercentile } from "utils/rarity";

const tokenProps = [
  { field: "tokenId", name: "Token ID" },
  { field: "weaponMouth", name: "Weapon / Mouth" },
  { field: "headgear", name: "Head" },
  { field: "body", name: "Body" },
  { field: "back", name: "Back" },
  { field: "eyes", name: "Eye" },
  { field: "emblem", name: "Emblem" },
  { field: "rank", name: "Rank" },
  // { field: "mintTx", name: "Mint TX" },
  // {field: "mintedAt", name: 'Mint Time'},
  // { field: "mintBlock",
  // { field: "isStaked",
  // { field: "stakedAt",
  // { field: "victim",
  // { field: "thief",
];

type Props = {
  token: any;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const rexSkipRarity = /Token|Type|RarityScore|Generation|RScore/;
const renderRow = (
  row: any,
  tokenId: string,
  traits: any,
  tokenTyp: string
): JSX.Element => {
  const rarity = traits?.[tokenTyp]?.[row.value]?.rarity;

  const rowValue = rarity == null ? "-" : (rarity * 100).toFixed(2);
  let middle = rexSkipRarity.test(row.name) ? "" : row.value;
  let right = rexSkipRarity.test(row.name) ? row.value : `(${rowValue} %)`;

  if (row.name.includes("RScore")) {
    middle = row.value;
    right = `(${row.value2} %)`;
  }

  return (
    <StyledTableRow key={`${tokenId}-${row.name}`} sx={{ overflowY: "auto" }}>
      <StyledTableCell component="th" scope="row">
        {row.name}
      </StyledTableCell>
      <StyledTableCell>{middle}</StyledTableCell>
      <StyledTableCell align="right">{right}</StyledTableCell>
    </StyledTableRow>
  );
};

export const TokenOverview = (props: Props): JSX.Element => {
  const token = props.token;
  const { traits, loaded } = useTraits();

  let child;
  if (token == null || !loaded) {
    child = <div></div>;
  } else {
    const rows = tokenProps.map((prop: any) => {
      const maxChar = 20;
      const { field, name } = prop;
      let value = token[field];
      if (typeof value === "string" && value.length > maxChar) {
        value = `${value.slice(0, maxChar)}...`;
      }

      return {
        field,
        name,
        value,
      };
    });

    const rarityField = {
      field: "rarityScore",
      name: "RScoreᵇᵉᵗᵃ (600 max)",
      value: getRarityScore(traits, token),
      value2: getRarityPercentile(traits, token),
    };
    rows.splice(1, 0, rarityField);

    child = (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "20vh",
          height: "100%",
        }}
      >
        <Box>
          <img src={token.image} style={{ height: "100%", width: "100%" }} />
        </Box>
        <TableContainer
          component={Box}
          sx={{ overflow: "auto", width: "100%" }}
        >
          <Table sx={{ width: "100%" }} aria-label="customized table">
            <TableBody>
              {rows.map((row) =>
                renderRow(row, token.tokenId ?? "", traits, token.typ)
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }

  return <div>{child}</div>;
};

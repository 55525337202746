import React from "react";
import { gql } from "@apollo/client";
import { Autocomplete, Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { client } from "clients/apolloClient";
import { mnaContract } from "utils/constants";

const SearchButton = styled("button")(({ theme }) => ({
  border: "0",
  width: "60px",
  borderBottomRightRadius: "4px",
  borderTopRightRadius: "4px",
  backgroundColor: theme.palette.secondary.main,
  marginLeft: "-3px",
}));

type Props = {
  maxWidth: string;
};

const GET_TOKEN = gql`
  query getToken($id: String!) {
    token(id: $id) {
      owner {
        id
      }
    }
  }
`;

const rexToken = /^\d+$/;

async function lookupAndNav(navigate: any, value: string) {
  if (rexToken.test(value)) {
    client
      .query({
        query: GET_TOKEN,
        variables: {
          id: `${mnaContract}-${value}`,
        },
      })
      .then((result) => {
        const token = result?.data.token;
        const address = token?.owner?.id ?? value;
        navigate(`/addresses/${address}`);
      });
  } else {
    navigate(`/addresses/${value}`);
  }
}

export const Search = (props: Props): JSX.Element => {
  const navigate = useNavigate();
  const [input, setInput] = React.useState<string>("");

  const onChange = (event: React.SyntheticEvent, value: string | null) => {
    event.preventDefault();
    if (value != null && value.length > 0) {
      lookupAndNav(navigate, value);
    }
  };

  const onInputChange = (event: React.SyntheticEvent, value: string) => {
    if (value !== input) {
      setInput(value);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: props.maxWidth,
      }}
    >
      <Autocomplete
        sx={{
          width: "100%",
        }}
        // onKeyPress={onKeyPress}
        onChange={onChange}
        onInputChange={onInputChange}
        freeSolo
        options={["options"]}
        renderInput={(props) => (
          <TextField
            sx={{
              backgroundColor: "white",
              borderTopLeftRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
            {...props}
            InputProps={{
              size: "small",
              type: "search",
              placeholder: "Search by address or tokenId",
              sx: {
                height: "36px",
              },
            }}
          />
        )}
      />
      <SearchButton
        onClick={() => input.length > 0 && lookupAndNav(navigate, input)}
      >
        <SearchIcon color="primary" />
      </SearchButton>
    </Box>
  );
};

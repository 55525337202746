import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";

type Props = {
  children: React.ReactNode;
  title: string;
  sx: any;
};
export const OverviewPane = (props: Props): JSX.Element => {
  const { children, title, sx } = props;
  return (
    <Grid item xs={6}>
      <Card
        variant="outlined"
        sx={{
          boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
          borderRadius: "4px",
          margin: "2rem 0 0 0",
          height: "100%",
          maxHeight: "24vh",
          ...sx,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              justifyContent: "flex-start",
              padding: "9px 0 0 9px",
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography gutterBottom>{title}</Typography>
          </Box>
          <Box
            sx={{
              justifyContent: "flex-start",
              height: "100%",
            }}
          >
            {children}
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
